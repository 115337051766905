import { datadogLogs } from '@datadog/browser-logs';
import { Router } from '@reach/router';
import { TFunction } from 'i18next';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import { Provider } from 'overmind-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginState } from '../interfaces/internal';
import css from './App.module.scss';
import DocumentsIcon from './assets/documents.svg';
import ReportsIcon from './assets/reports.svg';
import { AutomationSupportWidget } from './components/AutomationSupportWidget/AutomationSupportWidget';
import { ContactWidget } from './components/ContactWidget/ContactWidget';
import { CookieConsentBanner } from './components/CookieConsentBanner/CookieConsentBanner';
import { KalmarInsightWidget } from './components/KalmarInsightWidget/KalmarInsightWidget';
import { MainContainer } from './components/MainContainer/MainContainer';
import { MaintenanceReportingWidget } from './components/MaintenanceReportingWidget/MaintenanceReportingWidget';
import { MaintenanceScheduleWidget } from './components/MaintenanceScheduleWidget/MaintenanceScheduleWidget';
import { MyCasesWidget } from './components/MyCasesWidget/MyCasesWidget';
import { MyEquipmentWidget } from './components/MyEquipmentWidget/MyEquipmentWidget';
import { MyPartsWidget } from './components/MyPartsWidget/MyPartsWidget';
import { StatusPage } from './components/StatusPage/StatusPage';
import { WidgetProps } from './components/Widget/Widget';
import { WidgetGrid } from './components/WidgetGrid/WidgetGrid';
import { WidgetRouter } from './components/WidgetRouter/WidgetRouter';
import { store, useActions, useOvermindState } from './state';
import { State } from './state/state';
import { getCaseManagementLink, getHostType, getInsightLink, mkEnv } from './utils';

if (getHostType() != 'local_development') {
    datadogLogs.init({
        clientToken: 'pub425c2573c33ab4e8cf7a7fa91ddfb2f3',
        site: 'datadoghq.eu/',
        forwardErrorsToLogs: true, // logs console.error logs, uncaught exceptions and network errors to datadog
        //forwardConsoleLogs: 'all', // use this if want to log ALL console.logs
        sampleRate: 100,
        service: 'my-kalmar',
        env: getHostType(),
    });
}

const getWidgets = (state: State, t: TFunction): WidgetProps[] => {
    const {
        canNavigateToMyParts,
        contact,
        insightLicenseCounts,
        isChineseCustomer,
        loadingStates,
        siteEquipment,
        user,
        userAccess,
        equipment,
    } = state;

    const actions = useActions();
    const insightLoginPath = user?.isAdmin ? 'internal' : 'login/salesforce';

    let shouldShowInsightWidget = userAccess.kalmarInsight.read;
    if (isChineseCustomer) {
        shouldShowInsightWidget = shouldShowInsightWidget && insightLicenseCounts.valid > 0;
    }

    const shouldShowContactsWidget = userAccess.contacts.read && !isNil(contact);
    const isMyPartsWidgetFirst = user?.myParts.active ?? false;
    const shouldShowEquipmentWidget = equipment.length > 0 || loadingStates.equipment !== 'successful';

    const myPartsWidget: WidgetProps | undefined = userAccess.salesOrders.read
        ? {
              id: 'my-parts',
              title: t('Parts order history'),
              content: <MyPartsWidget />,
              footerButtons: canNavigateToMyParts
                  ? [
                        {
                            variant: 'secondary',
                            text: t('Open MyKalmar STORE'),
                            linkTo: `${mkEnv.myPartsURL}/orders`,
                            id: 'open-my-parts-button',
                        },
                    ]
                  : undefined,
              loadingState: loadingStates.salesOrders,
              failureProps: {
                  translationKey: 'Loading sales orders failed',
                  retry: actions.getSalesOrders,
              },
          }
        : undefined;

    return compact([
        isMyPartsWidgetFirst && myPartsWidget,
        userAccess.equipment.read && shouldShowEquipmentWidget
            ? {
                  id: 'my-equipment',
                  title: t('My Equipment'),
                  content: <MyEquipmentWidget />,
                  footerButtons: [
                      {
                          variant: 'secondary',
                          text: t('Show all ({{ equipmentLength }}) equipment', {
                              equipmentLength: siteEquipment.length,
                          }),
                          linkTo: '/my-equipment',
                          id: 'show-all-equipment-button',
                      },
                  ],
                  loadingState: loadingStates.equipment,
                  failureProps: {
                      translationKey: 'Loading equipment failed',
                      retry: actions.getEquipment,
                  },
              }
            : undefined,
        userAccess.cases.read
            ? {
                  id: 'my-cases',
                  title: t('My Cases'),
                  content: <MyCasesWidget />,

                  headerBadge:
                      state.caseUpdates.length > 0
                          ? {
                                content: String(state.caseUpdates.length),
                                onClick: actions.clearCaseUpdates,
                            }
                          : undefined,
                  headerButtons: [
                      {
                          icon: DocumentsIcon,
                          linkTo: getCaseManagementLink('/s/report/Report/Recent'),
                          buttonId: 'my-cases-recent-report-button',
                          // id already used in WidgetProps, so use buttonId instead for setting id for button
                      },
                      {
                          icon: ReportsIcon,
                          linkTo: getCaseManagementLink('/s/content'),
                          buttonId: 'my-cases-content-button',
                      },
                  ],
                  footerButtons: compact([
                      {
                          variant: 'secondary',
                          text: t('Show cases'),
                          linkTo: getCaseManagementLink('/s/recordlist/Case/Default'),
                          id: 'show-cases-button',
                      },
                      userAccess.cases.create
                          ? {
                                variant: 'primary',
                                text: `+ ${t('Create new')}`,
                                linkTo: getCaseManagementLink('/s/create-case'),
                                id: 'create-new-button',
                            }
                          : undefined,
                  ]),
                  loadingState: loadingStates.cases,
                  failureProps: {
                      translationKey: 'Loading cases failed',
                      retry: actions.getCases,
                  },
              }
            : undefined,
        !isMyPartsWidgetFirst && myPartsWidget,
        userAccess.maintenancePlans.read
            ? {
                  id: 'maintenance-schedule',
                  title: t('Maintenance schedule'),
                  content: <MaintenanceScheduleWidget />,
                  footerButtons: [
                      {
                          variant: 'secondary',
                          text: t('Show on calendar'),
                          linkTo: '/maintenance-schedule',
                          id: 'show-maintenance-calendar-button',
                      },
                  ],
                  loadingState: loadingStates.maintenancePlans,
                  failureProps: {
                      translationKey: 'Loading maintenance schedule failed',
                      retry: actions.getInitialMaintenancePlans,
                  },
              }
            : undefined,
        userAccess.maintenanceReporting.read
            ? {
                  id: 'maintenance-reporting',
                  title: t('Maintenance reporting'),
                  content: <MaintenanceReportingWidget />,
              }
            : undefined,
        shouldShowInsightWidget
            ? {
                  id: 'kalmar-insight',
                  title: 'Kalmar Insight',
                  content: <KalmarInsightWidget />,
                  footerButtons: [
                      {
                          variant: 'secondary',
                          text: t('Open Kalmar Insight'),
                          linkTo: getInsightLink(isChineseCustomer, insightLoginPath),
                          id: 'open-kalmar-insight-button',
                      },
                  ],
              }
            : undefined,
        shouldShowContactsWidget
            ? {
                  id: 'contacts',
                  title: t('Contact Kalmar'),
                  content: <ContactWidget />,
              }
            : undefined,
        userAccess.automationSupport.read
            ? {
                  id: 'automation-support',
                  title: t('Automation support'),
                  content: <AutomationSupportWidget />,
              }
            : undefined,
    ]);
};

const AppContent: React.FC = () => {
    const state = useOvermindState();
    const { loginState } = state;

    const { handleLogin } = useActions();

    const { t } = useTranslation();
    const widgets = getWidgets(state, t);

    if (loginState === LoginState.LoggedOut) {
        return (
            <StatusPage
                buttonProps={{
                    onClick: () => handleLogin(),
                    text: t('Login again'),
                    variant: 'secondary',
                }}
                isLoading={false}
                title={t('You are now logged out from MyKalmar')}
            />
        );
    }

    if (loginState === LoginState.NotStarted) {
        return <StatusPage isLoading={true} title={`${t('Loading MyKalmar')}...`} />;
    }

    if (loginState === LoginState.Successful) {
        return (
            <Router className={css.router}>
                <MainContainer path="/" default>
                    <WidgetGrid path="/" widgets={widgets} />
                    <WidgetRouter default widgets={widgets} />
                </MainContainer>
            </Router>
        );
    }

    return (
        <StatusPage
            buttonProps={{
                onClick: () => window.location.reload(),
                text: t('Try again'),
                variant: 'secondary',
            }}
            isLoading={false}
            title={t('Something went wrong')}
        />
    );
};

const App: React.FC = () => {
    return (
        <Provider value={store}>
            <AppContent key={store.state.language} />
            <CookieConsentBanner />
        </Provider>
    );
};

export default App;
