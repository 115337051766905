import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';
import { KALMAR_PRIMARY_GRAPE, KALMAR_WHITE } from '../../colors';

export const Tooltip = (props: TooltipProps): JSX.Element => (
    <MUITooltip
        {...props}
        componentsProps={{
            ...props.componentsProps,
            tooltip: {
                ...props.componentsProps?.tooltip,
                sx: {
                    backgroundColor: KALMAR_PRIMARY_GRAPE,
                    borderRadius: 0,
                    color: KALMAR_WHITE,
                    fontSize: '1.2rem',
                    padding: '0.5rem',
                    ...props.componentsProps?.tooltip?.sx,
                },
            },
        }}
    />
);
