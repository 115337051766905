import camelCase from 'lodash/camelCase';
import queryString from 'query-string';
import React from 'react';
import { Equipment } from '../../../interfaces/customer-api';
import { hasIconForEquipmentType } from '../../equipment-icons';
import { useOvermindState } from '../../state';
import { cns } from '../../utils';
import { EquipmentIcon } from '../EquipmentIcon/EquipmentIcon';
import { Link } from '../Link/Link';
import css from './MyEquipmentWidget.module.scss';
import KalmarLogo from '../../assets/kalmar-logo-new-round.svg';

interface EquipmentTypeCounts {
    [key: string]: number;
}

function getEquipmentCategories(equipment: Equipment[]) {
    // Count 'Other' equipment separately, as it should be shown last
    let otherCount = 0;
    const eqTypeCounts: EquipmentTypeCounts = equipment.reduce<EquipmentTypeCounts>(
        (acc: EquipmentTypeCounts, equ: Equipment) => {
            const label = hasIconForEquipmentType(equ.type) && equ.type;

            if (label) {
                acc[label] = acc[label] ? acc[label] + 1 : 1;
            } else {
                otherCount++;
            }
            return acc;
        },
        {},
    );

    const countElements = Object.entries(eqTypeCounts).map(([type, count]) => (
        <Link
            id={`my-equipment-${type}-button`}
            key={type}
            className={cns(css.equipmentType, camelCase(type))}
            to={`/my-equipment?${queryString.stringify({ search: type })}`}
        >
            <EquipmentIcon type={type} />
            <span className={css.count}>{count}</span>
        </Link>
    ));

    if (otherCount > 0) {
        countElements.push(
            <div key="other" className={css.equipmentType}>
                <span className={css.otherGroupPlaceholder}>
                    <KalmarLogo />
                </span>
                <span className={css.count}>{otherCount}</span>
            </div>,
        );
    }

    return countElements;
}

export const MyEquipmentWidget: React.FunctionComponent = () => {
    const { siteEquipment } = useOvermindState();
    return (
        <div className={css.myEquipmentWidget}>
            <div className={css.equipmentTypeContainer}>{getEquipmentCategories(siteEquipment)}</div>
        </div>
    );
};
