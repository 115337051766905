import { datadogLogs } from '@datadog/browser-logs';
import { globalHistory, navigate } from '@reach/router';
import sortedIndexBy from 'lodash/sortedIndexBy';
import sortedLastIndexBy from 'lodash/sortedLastIndexBy';
import { DateTime, DateTimeUnit, Duration, Interval } from 'luxon';
import queryString from 'query-string';

import { AdminAPIResponse, CustomerAPIResponse, ErrorResponse } from '../interfaces/backend-api';
import { API_DATE_FORMAT, MaintenancePlan } from '../interfaces/customer-api';
import {
    CustomerUser,
    DateRange,
    InfiniteDateRange,
    InfiniteISOTimeRange,
    InternalUser,
    ISOTimeRange,
    Language,
    LoadingState,
    PublicEnv,
    UserLanguage,
} from '../interfaces/internal';

const LOCAL_STORAGE_NEXT_URL_KEY = 'mykalmar-next-url';
const SESSION_STORAGE_ADMIN_STATUS_KEY = 'mykalmar-admin-status';
const SESSION_STORAGE_ADMIN_SELECTED_CUSTOMER_KEY = 'mykalmar-selected-customer-number';

export const IS_PRODUCTION_ENV = location.host === 'www.mykalmar.com';

// TODO: configure logging properly
export const logger = console;

export const mkEnv: PublicEnv = { ...window.__mkEnv };

export class APIError extends Error {
    constructor(message: string, public statusCode: number = 500) {
        super(message);
        Object.setPrototypeOf(this, APIError.prototype);
    }
}

export function removeHash(): void {
    const location = window.location;
    if ('replaceState' in history) {
        history.replaceState('', document.title, location.pathname + location.search);
    } else {
        // Prevent scrolling by storing the page's current scroll offset
        const scrollV = document.body.scrollTop;
        const scrollH = document.body.scrollLeft;

        location.hash = '';

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

type MaybeClassName = string | boolean | null | undefined;

export function cns(...classNames: MaybeClassName[]): string {
    const strings = classNames.filter((className) => typeof className === 'string');
    return strings.join(' ');
}

async function expireSession() {
    // Dynamic import to avoid circular dependencies
    const { store } = await import('./state');
    store.actions.expireSession();
}

async function handleAPIResponse<T>(response: Response): Promise<T> {
    if (response.status === 401) {
        // Session expired, show dialog
        await expireSession();
        throw new APIError(response.statusText, 401);
    } else if (response.status >= 400) {
        const result = (await response.json()) as ErrorResponse;
        const message = result.errors && result.errors.length > 0 ? result.errors[0].title : 'API error';
        throw new APIError(message, response.status);
    }

    return response.json() as Promise<T>;
}

export async function queryAPI<T>(endpoint: string): Promise<T> {
    const url = `${mkEnv.apiBaseURL}/api/v1${endpoint}`;
    const response = await fetch(url, {
        credentials: 'include',
    });
    // Log response error status to datadog
    if (response.status >= 400) datadogLogs.logger.error('Error ' + response.status.toString() + ' at ' + endpoint);
    return handleAPIResponse(response);
}

export async function postToAPI<TBody, TResult>(endpoint: string, body: TBody): Promise<TResult> {
    const url = `${mkEnv.apiBaseURL}/api/v1${endpoint}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include',
    });
    return handleAPIResponse(response);
}

export async function queryCustomerAPI<T>(endpoint: string): Promise<CustomerAPIResponse<T>> {
    return queryAPI<CustomerAPIResponse<T>>(endpoint);
}

export async function queryAdminAPI<T>(endpoint: string): Promise<AdminAPIResponse<T>> {
    return queryAPI<AdminAPIResponse<T>>(endpoint);
}

export const parseUser = (user: string): InternalUser | CustomerUser => {
    const parsedUser = JSON.parse(user);
    if (parsedUser.isAdmin) {
        return parsedUser as InternalUser;
    }
    return parsedUser as CustomerUser;
};

export const getLanguageCode = (language: UserLanguage): Language | undefined => Language[language];

export const setNextURL = (): void => {
    const excludedURLs = ['/login', '/internal'];
    const { next } = queryString.parse(window.location.search);
    if (typeof next === 'string') {
        const isExcluded = excludedURLs.some((url) => url === next);
        if (!isExcluded && localStorage) {
            localStorage.setItem(LOCAL_STORAGE_NEXT_URL_KEY, decodeURIComponent(next));
        }
    }
};

export const navigateToNextURL = (): void => {
    if (localStorage) {
        const nextURL = localStorage.getItem(LOCAL_STORAGE_NEXT_URL_KEY);
        if (nextURL) {
            navigate(nextURL);
            clearNextURL();
        }
    }
};

export const clearNextURL = (): void => {
    if (localStorage) {
        localStorage.removeItem(LOCAL_STORAGE_NEXT_URL_KEY);
    }
};

export const saveAdminStatus = (isAdmin: boolean): void => {
    if (sessionStorage) {
        sessionStorage.setItem(SESSION_STORAGE_ADMIN_STATUS_KEY, String(isAdmin ? 1 : 0));
    }
};

export const getLoginURL = (): string => {
    if (sessionStorage) {
        const adminStatus = sessionStorage.getItem(SESSION_STORAGE_ADMIN_STATUS_KEY);
        if (adminStatus) {
            const isAdmin = parseInt(adminStatus, 10);
            return isAdmin ? '/internal' : '/login';
        }
    }
    return '/login';
};

export const saveCustomerNumber = (customerNumber: string): void => {
    if (sessionStorage) {
        sessionStorage.setItem(SESSION_STORAGE_ADMIN_SELECTED_CUSTOMER_KEY, customerNumber);
    }
};

export const getSavedCustomerNumber = (): string | null => {
    if (sessionStorage) {
        const customerNumber = sessionStorage.getItem(SESSION_STORAGE_ADMIN_SELECTED_CUSTOMER_KEY);
        if (customerNumber) {
            return customerNumber;
        }
    }
    return null;
};

const isLoadingState = (targetLoadingState: LoadingState, loadingStates?: LoadingState | LoadingState[]): boolean => {
    const _loadingStates = Array.isArray(loadingStates) ? loadingStates : [loadingStates];

    return _loadingStates.some((loadingState) => loadingState === targetLoadingState);
};

export const isLoading = (loadingStates?: LoadingState | LoadingState[]): boolean =>
    isLoadingState(LoadingState.Loading, loadingStates);

export const isFailed = (loadingStates?: LoadingState | LoadingState[]): boolean =>
    isLoadingState(LoadingState.Failed, loadingStates);

export const isSuccessful = (loadingStates?: LoadingState | LoadingState[]): boolean =>
    isLoadingState(LoadingState.Successful, loadingStates);

export function formatHoursAndMinutes(minutes: number): string {
    const duration = Duration.fromObject({ minutes });
    const isEvenHour = minutes > 0 && minutes % 60 === 0;
    if (isEvenHour) {
        return duration.toFormat("h'h'");
    } else if (minutes < 60) {
        return duration.toFormat("m'min'");
    }

    return duration.toFormat("h'h' m'min'");
}

export function formatDate(date: DateTime): string {
    if (!date.isValid) {
        return '-';
    }
    return date
        .setLocale(navigator.language)
        .toLocaleString({ ...DateTime.DATE_SHORT, day: '2-digit', month: '2-digit' });
}

export function formatTime(date: DateTime): string {
    if (!date.isValid) {
        return '-';
    }
    return date.setLocale(navigator.language).toLocaleString({ ...DateTime.TIME_24_SIMPLE });
}

export function dateRangeToTimeRange(dateRange: DateRange): ISOTimeRange {
    return {
        startTime: DateTime.fromFormat(dateRange.startDate, API_DATE_FORMAT).startOf('day').toISO(),
        endTime: DateTime.fromFormat(dateRange.endDate, API_DATE_FORMAT).endOf('day').toISO(),
    };
}

export function possiblyInfiniteDateRangeToTimeRange(dateRange: InfiniteDateRange): InfiniteISOTimeRange {
    return {
        startTime: DateTime.fromFormat(dateRange.startDate, API_DATE_FORMAT).startOf('day').toISO(),
        endTime: dateRange.endDate
            ? DateTime.fromFormat(dateRange.endDate, API_DATE_FORMAT).endOf('day').toISO()
            : undefined,
    };
}

export function getIntervals(start: DateTime, end: DateTime, splitBy: DateTimeUnit): Interval[] {
    return Interval.fromDateTimes(start.startOf(splitBy), end.endOf(splitBy)).splitBy(
        Duration.fromObject({ [splitBy]: 1 }),
    );
}

/**
 * Can be thrown to display errors that are intended to be seen by the user
 */
export class UserError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, UserError.prototype);
    }
}
export const getMaintenancePlansForDateRange = (
    maintenancePlans: MaintenancePlan[],
    dateRange: DateRange,
): MaintenancePlan[] => {
    if (maintenancePlans.length === 0) return [];

    const indexByKey: keyof MaintenancePlan = 'date';

    // Maintenance plans are sorted, so we can use binary search
    const firstIndexOfDate = sortedIndexBy(
        maintenancePlans as Partial<MaintenancePlan>[],
        { date: dateRange.startDate },
        indexByKey,
    );
    const lastIndexOfDate = sortedLastIndexBy(
        maintenancePlans as Partial<MaintenancePlan>[],
        { date: dateRange.endDate },
        indexByKey,
    );

    return maintenancePlans.slice(firstIndexOfDate, lastIndexOfDate);
};

export const initGoogleTagManager = (gtmId: string, user: CustomerUser | InternalUser): void => {
    const existingElement = document.getElementById('GTM');
    if (existingElement !== null) {
        logger.log('GTM already initialized');
        return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'initData',
        originalLocation: document.location.href,
        pageData: {
            pagePath: document.location.pathname + document.location.search,
        },
        userData: {
            companyId: user.isAdmin ? 'internal' : user.sapCustomerNumber || 'unknown',
            userId: user.id,
            role: user.role,
        },
    });
    window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });

    const scriptElement = document.createElement('script');
    scriptElement.id = 'GTM';
    scriptElement.async = true;
    scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(scriptElement);

    globalHistory.listen(({ location }) => {
        window.dataLayer.push({
            event: 'pageEvent',
            pageData: { pagePath: location.pathname + location.search },
        });
    });
};

export function getCaseManagementLink(path: string): string {
    // add session=true param so SF knows not to redirect back to MyKalmar
    return `${mkEnv.salesforceCommunityURL}${path}?session=true`;
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export function getInsightLink(isChineseCustomer: boolean, path?: string): string {
    const baseURL = isChineseCustomer ? mkEnv.insightURLChina : mkEnv.insightURL;
    return [baseURL, path].filter(nonNullable).join('/');
}

export function isCustomerUser(user: InternalUser | CustomerUser | null): user is CustomerUser {
    return user !== null && !user.isAdmin;
}

export function getShortEquipmentType(equipmentType: string): string {
    // If type is one word, dont shorten
    if (!/\s/.test(equipmentType)) {
        return equipmentType;
    }

    // Return the first character of every word in uppercase
    const matches = equipmentType.match(/\b(\w)/g);
    if (matches) {
        return matches.join('').toUpperCase();
    }

    // Fallback:
    return equipmentType;
}

export const getHostType = () => {
    const host = location.host;
    if (host === 'www.mykalmar.com') return 'production';
    if (host === 'mykalmar-pilot.mykalmar.ncic.cargotec.com') return 'pilot';
    if (host === 'mykalmar-dev.mykalmar.ncic.cargotec.com') return 'dev';
    if (host.startsWith('localhost') || host.startsWith('127.0.0.1')) return 'local_development';

    return 'other';
};
