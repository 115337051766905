import React from 'react';
import { useTranslation } from 'react-i18next';
import css from './Footer.module.scss';
import FacebookIcon from '../../assets/facebook.svg';
import TwitterIcon from '../../assets/twitter.svg';
import YoutubeIcon from '../../assets/youtube.svg';
import TiktokIcon from '../../assets/tiktok.svg';
import LinkedInIcon from '../../assets/linkedin-icon.svg';
import InstagramIcon from '../../assets/instagram-icon.svg';
import KalmarLogo from '../../assets/kalmar-logo-new.svg';
import { KALMAR_NEW_FOOTER } from '../../colors';
import { Link } from '../Link/Link';
import { getInsightLink, mkEnv } from '../../utils';
import { useOvermindState } from '../../state';

export const Footer: React.FC = () => {
    const { t } = useTranslation('Footer');
    const { contact, user, isChineseCustomer } = useOvermindState();

    const insightLoginPath = user?.isAdmin ? 'internal' : 'login/salesforce';
    const isChinese = isChineseCustomer === true ? true : false;

    return (
        <div style={{ background: KALMAR_NEW_FOOTER }}>
            <div className={css.footer}>
                <KalmarLogo />
                <div className={css.footerContent}>
                    <div className={css.content}>
                        <div className={css.contacts}>
                            <h2>{t('MyKalmar')}</h2>
                            <div className={css.address}>
                                {contact?.phone}
                                <p>{contact?.email || 'mykalmar.support@kalmarglobal.com'}</p>
                            </div>
                        </div>
                        <div className={css.links}>
                            <Link id="my-equipment-link-button" to="/my-equipment">
                                {t('My Equipment')}
                            </Link>
                            <Link id="mykalmar-store-link-button" to={`${mkEnv.myPartsURL}`}>
                                {t('MyKalmar STORE')}
                            </Link>
                            <Link id="to-insight-link-button" to={getInsightLink(isChinese, insightLoginPath)}>
                                {t('Insight')}
                            </Link>
                        </div>
                        <div className={css.kalmar}>
                            <a
                                id="privacy-policy-link-button"
                                href="https://www.kalmarglobal.com/legal-notice/privacy-policy/"
                            >
                                {t('Privacy policy')}
                            </a>
                            <a
                                id="conditions-link-button"
                                href="https://www.kalmarglobal.com/legal-notice/kalmar-digital-service-terms-and-conditions/"
                            >
                                {t('General conditions')}
                            </a>
                            <p>{t('Kalmar in Social Media')}</p>
                            <div>
                                <a href="https://www.linkedin.com/company/kalmar" target="_blank" rel="noreferrer">
                                    <LinkedInIcon />
                                </a>
                                <a href="https://www.facebook.com/kalmarglobal" target="_blank" rel="noreferrer">
                                    <FacebookIcon />
                                </a>
                                <a href="https://twitter.com/Kalmarglobal" target="_blank" rel="noreferrer">
                                    <TwitterIcon />
                                </a>
                                <a href="https://www.youtube.com/KalmarGlobal" target="_blank" rel="noreferrer">
                                    <YoutubeIcon />
                                </a>
                                <a href="https://www.instagram.com/kalmarglobal/" target="_blank" rel="noreferrer">
                                    <InstagramIcon />
                                </a>
                                <a href="https://www.tiktok.com/@kalmarofficial" target="_blank" rel="noreferrer">
                                    <TiktokIcon />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
