export default {
    App: {
        'Contact Kalmar': 'Póngase en contacto con Kalmar',
        'Create new': 'Crear nuevo',
        'Download failed': 'Error de descarga',
        'Failed to get service reports': 'Error al obtener informes de servicio',
        'Loading MyKalmar': 'Cargando MyKalmar',
        'Login again': 'Volver a iniciar sesión',
        'Maintenance reporting': 'Informes de mantenimiento',
        'Maintenance schedule': 'Programa de mantenimiento',
        'My Cases': 'Mis Casos',
        'MyKalmar homepage': 'Página de inicio de MyKalmar',
        'MyParts': 'MyParts',
        'Navigating to MyParts failed': 'Error al navegar hasta MyParts',
        'No service reports found': 'No se han encontrado informes de servicio',
        'None': 'Ninguno',
        'Open Kalmar Insight': 'Abierta Kalmar Insight',
        'Open MyKalmar STORE': 'Abrir MyKalmar STORE',
        'Parts order history': 'Historial de pedidos',
        'Show all ({{ equipmentLength }}) equipment': 'Mostrar todos los equipos ({{ equipmentLength }})',
        'Show all Kalmar contacts': 'Mostrar todos los contactos de Kalmar',
        'Show cases': 'Mostrar casos',
        'Show on calendar': 'Mostrar en el calendario',
        'Try again': 'Volver a intentarlo',
        'You are now logged out from MyKalmar': 'Ya ha cerrado la sesión de MyKalmar',
    },
    Common: {
        'Availability': 'Disponibilidad',
        'Call the number': 'Llamar al número',
        'called': 'Llamado',
        'completed': 'Completado',
        'Contracts': 'Contratos',
        'declined': 'Rechazado',
        'Maintenance': 'Mantenimiento',
        'Monthly': 'Mensual',
        'Monthly report': 'Informe mensual',
        'My Equipment': 'Mi Equipo',
        'N/A': 'No aplicable',
        'Name': 'Nombre',
        'No data': 'Sin datos',
        'No data for this time period': 'Sin datos para este periodo de tiempo',
        'OK': 'OK',
        'ordered': 'Pedido',
        'Other': 'Otro',
        'Response Time': 'Tiempo de respuesta',
        'scheduled': 'Programado',
        'See details': 'Consultar detalles',
        'Serial number': 'Número de serie',
        'Site': 'Lugar',
        'Something went wrong': 'Ha ocurrido un problema',
        'Status': 'Estado',
        'today': 'hoy',
        'Type': 'Tipo',
        'Unknown': 'Desconocido',
        'Yearly': 'Anualmente',
        'Yearly trend': 'Tendencia anual',
    },
    ContactWidget: {
        'Contact us!': '¡Contáctenos!',
        'Email': 'Correo electrónico',
        'Need help?  Or need to know more about our services?': '¿Necesita ayuda? ¿Necesita saber más sobre nuestros servicios?',
        'Phone': 'Teléfono',
        'Send email': 'Enviar correo electrónico',
    },
    CookieConsentBanner: {
        'Learn more': 'Aprende más',
        'This website makes use of cookies to enhance browsing experience and provide additional functionality.': 'Este sitio web utiliza cookies para mejorar la experiencia de navegación y proporcionar funcionalidad adicional.',
    },
    CustomerSelector: {
        'No results found with': 'No se encontraron resultados con',
        'Search with name, city, street address or sold-to number': 'Search with name, city, street address or sold-to number',
        'Select customer': 'Seleccionar cliente',
    },
    EquipmentAvailability: {
        'Equipment Type': 'Tipos de equipos',
        'Month': 'Mes',
        'Quarter': 'Trimestre',
        'Year': 'Año',
    },
    EquipmentAvailabilityByType: {
        'Time spent': 'Tiempo dedicado',
    },
    Footer: {
        'General conditions': 'Condiciones generales',
        'Kalmar in Social Media': 'Kalmar en las redes sociales',
        'Kalmar Digital Service Terms & Conditions': 'Términos y condiciones del servicio digital de Kalmar',
        'Privacy policy': 'Política de privacidad',
    },
    KalmarInsightWidget: {
        'equipment': 'equipo',
        'Expired license': 'Licencia caducada',
        'License expiring soon': 'Licencia caduca pronto',
        'Manage users': 'Administrar usuarios',
        'Number of Kalmar Insight users': 'Número de Usuarios de Kalmar Insight',
        'Valid license': 'Licencia válida',
    },
    LanguageSelector: {
        'Cancel': 'Cancelar',
        'Select language': 'Seleccione el idioma',
    },
    LoadingFailure: {
        'Loading cases failed': 'Error al cargar casos',
        'Loading equipment availability failed': 'Error al cargar disponibilidad de equipo',
        'Loading equipment failed': 'Error al cargar equipo',
        'Loading failed': 'Error al cargar',
        'Loading maintenance schedule failed': 'Error al cargar programa de mantenimiento',
        'Loading preventive maintenance data failed': 'Error al cargar datos de mantenimiento preventivo',
        'Loading response time failed': 'Error al cargar tiempo de respuesta',
        'Loading sales orders failed': 'Error al cargar órdenes de venta',
        'Retry': 'Reintentar',
    },
    MaintenanceCalendar: {
        'Equipment name': 'Nombre del equipo',
        'Maintenance description': 'Descripción del mantenimiento',
        'No maintenance scheduled for the selected day.': 'No hay mantenimiento programado para el día seleccionado.',
    },
    MaintenanceReporting: {
        'Equipment availability': 'Disponibilidad del equipo',
        'Preventive maintenance': 'Mantenimiento preventivo',
        
    },
    MaintenanceReportingWidget: {
        'Average duration from breakdown to the first service operation start': 'Average duration from breakdown to the first service operation start',
        'Average percentage the equipment was available': 'Porcentaje medio que el equipo estaba disponible',
        'Planned maintenance operations that were completed on time': 'Planned maintenance operations that were completed on time',
    },
    MaintenanceScheduleWidget: {
        'No upcoming maintenances': 'Ningún mantenimiento próximo',
        'Upcoming maintenances': 'Mantenimientos próximos',
    },
    MasterHeader: {
        'Go to MyKalmar STORE or Kalmar Insight': 'Vaya a MyKalmar STORE o Kalmar Insight',
        'Language': 'Idioma',
        'Log out': 'Cerrar sesión',
        'MyKalmar profile': 'MyKalmar perfil',
        'You can go to MyKalmar STORE and Kalmar Insight by clicking here': 'Puede ir a MyKalmar STORE y Kalmar Insight haciendo clic aquí',
    },
    MyCasesWidget: {
        'An unknown value': 'Un valor desconocido',
        'caseUpdateFieldChange': '{{fieldName}} cambiado de <2>{{oldValue}}</2> a <4>{{newValue}}</4>',
        'Comment': 'Comentario',
        'n/a': 'n/a',
        'New comment': 'Nuevo comentario',
        'No recent updates to cases': 'No hay actualizaciones recientes de los casos',
        'Priority': 'Prioridad',
        'Recent updates': 'Actualizaciones recientes',
        'Resolution_Date__c': 'Resolution date',
    },
    MyEquipment: {
        'Contracts': 'Contratos',
        'Description': 'Descripción',
        'Digital subscriptions': 'Suscripciones digitales',
        'Equipment name': 'Nombre del equipo',
        'Insight': 'Información',
        'Leasing': 'Arrendamiento',
        'Model': 'Modelo',
        'Search equipment with name, site, serial number or type': 'Buscar equipos con nombre, lugar, número de serie o tipo',
        'Search with name, serial number or type': 'Buscar con nombre, número de serie o tipo',
        'Serial number': 'Número de serie',
        'Short-term rental': 'Alquiler a corto plazo',
    },
    MyEquipmentItem: {
        'Contract ends': 'El contrato finaliza',
        'Contract started': 'El contrato comenzó',
        'Description': 'Descripción',
        'Equipment details': 'Detalles del equipo',
        'insight': 'Información',
        'leasing': 'Arrendamiento',
        'maintenance': 'Mantenimiento',
        'Maintenance schedule': 'Programa de mantenimiento',
        'No equipment found': 'No se ha encontrado ningún equipo',
        'Order parts': 'Pedir piezas',
        'Owner': 'Propietario',
        'rental-short-term': 'Alquiler a corto plazo',
        'See Insights': 'Ver Información',
        'Serial number': 'Número de serie',
        'Today': 'Hoy',
    },
    MyEquipmentWidget: {},
    MyPartsWidget: {
        'Customer PO': 'PO del cliente',
        'Delivered': 'Entregado',
        'My last parts orders': 'Mis últimos pedidos de piezas',
        'No orders': 'Sin pedidos',
        'Note: this order will be delivered in multiple shipments.': 'Nota: Este pedido se entregará en varios envíos.',
        'Order at warehouse': 'En el almacén',
        'Order number': 'Número de orden',
        'Order received': 'Pedido recibido',
        'Processing': 'En proceso',
        'Shipment': 'Envío',
        'Shipped': 'Enviado',
        'Tracking': 'Seguimiento',
    },
    NotFound: {
        'Go to home page': 'Ir a la página de inicio',
        'Page not found': 'Página no encontrada',
        'The page may have been moved or you may not have the necessary rights to access this page': 'Es posible que la página haya sido movida o que no tenga los derechos necesarios para acceder a ella',
    },
    PreventiveMaintenanceCompletion: {
        'Completed': 'Completado',
        'completed in': 'completado en',
        'Count': 'Recuento',
        'No data for': 'Sin datos para',
        'Not completed': 'No completado',
        'Percentage': 'Porcentaje',
        'Planned': 'Planeado',
        'tasks': 'tareas',
    },
    ResponseTime: {
        'Action started': 'Acción iniciada',
        'avg. response time': 'tiempo de respuesta medio',
        'Call started': 'Llamada iniciada',
        'in': 'pulg',
        'Operation': 'Funcionamiento',
        'Response time': 'Tiempo de respuesta',
    },
    SessionExpirationModal: {
        'Log in again': 'Volver a iniciar sesión',
        'You have been logged out due to inactivity': 'Se ha cerrado la sesión por inactividad',
        'Your session has expired': 'Su sesión ha expirado',
    },
    SiteSelector: {
        'Customer': 'Cliente',
        'No sites with equipment': 'Ningún lugar con equipos',
        'Select a customer': 'Seleccionar un cliente',
        'Selected': 'Seleccionado',
        'Show all sites': 'Mostrar todos los sitios',
        'Showing all sites': 'Mostrando todos los lugares',
    },
    TimeUnitLinks: {
        'Show': 'Mostrar',
    },
};
