import { ResourceLanguage } from 'i18next';
import queryString from 'query-string';
import { io } from 'socket.io-client';
import { Customer } from '../../interfaces/admin-api';
import { ResultsResponse } from '../../interfaces/backend-api';
import {
    Components,
    Equipment,
    EquipmentAvailabilityKPI,
    MaintenancePlan,
    MaintenanceStatus,
    PreventiveMaintenanceCompletionKPI,
    ResponseTimeKPI,
    SalesforceCase,
    SalesOrder,
    Site,
} from '../../interfaces/customer-api';
import {
    CustomerUser,
    InfiniteDateRange,
    InternalUser,
    KPIParams,
    Language,
    UserFlags,
} from '../../interfaces/internal';
import { CaseUpdatePayload } from '../../interfaces/realtime-api';
import i18n from '../i18n';
import {
    dateRangeToTimeRange,
    logger,
    mkEnv,
    possiblyInfiniteDateRangeToTimeRange,
    postToAPI,
    queryAdminAPI,
    queryAPI,
    queryCustomerAPI,
} from '../utils';

export const maintenancePlanCache = new Map<string, MaintenancePlan[]>();

export const getCacheKey = (customerNumber: string | null, dateRange: InfiniteDateRange): string =>
    `${customerNumber}:${dateRange.startDate}`;

export const api = {
    async loadSites(customerNumber: string | null): Promise<Site[]> {
        if (customerNumber) {
            const response = await queryCustomerAPI<Site[]>(`/customers/${customerNumber}/sites`);
            if (response.status === 200) {
                return response.results;
            }
            return [];
        }
        return [];
    },
    async loadEquipment(customerNumber: string | null): Promise<Equipment[]> {
        if (customerNumber) {
            const response = await queryCustomerAPI<Equipment[]>(`/customers/${customerNumber}/equipment`);
            if (response.status === 200) {
                return response.results;
            }
        }
        return [];
    },
    async loadCases(
        customerNumber: string | null,
        user: CustomerUser | InternalUser | null,
    ): Promise<SalesforceCase[]> {
        if (customerNumber && user?.contactId) {
            const response = await queryCustomerAPI<SalesforceCase[]>(`/customers/${customerNumber}/cases`);
            if (response.status === 200) {
                return response.results;
            }
            return [];
        }
        return [];
    },
    async loadMaintenancePlans(
        dateRange: InfiniteDateRange,
        customerNumber: string | null,
    ): Promise<MaintenancePlan[]> {
        if (customerNumber) {
            const timeRange = possiblyInfiniteDateRangeToTimeRange(dateRange);
            const query = queryString.stringify(timeRange);
            const response = await queryCustomerAPI<MaintenancePlan[]>(
                `/customers/${customerNumber}/maintenance-plans?${query}`,
            );
            if (response.status === 200) {
                const cacheKey = getCacheKey(customerNumber, dateRange);
                maintenancePlanCache.set(cacheKey, response.results);
                return response.results;
            }
        }
        return [];
    },
    async loadEquipmentAvailabilityKPI(
        params: KPIParams,
        customerNumber: string | null,
    ): Promise<EquipmentAvailabilityKPI[]> {
        const timeRange = dateRangeToTimeRange(params);
        if (customerNumber) {
            const query = queryString.stringify({ ...timeRange, splitBy: params.splitBy });
            const response = await queryCustomerAPI<EquipmentAvailabilityKPI[]>(
                `/customers/${customerNumber}/kpi/equipment-availability?${query}`,
            );
            if (response.status === 200) {
                return response.results;
            }
        }

        return [
            {
                average: null,
                byType: null,
                bySite: null,
                startTime: timeRange.startTime,
                endTime: timeRange.endTime,
            },
        ];
    },
    async loadPMCompletionKPI(
        params: KPIParams,
        customerNumber: string | null,
    ): Promise<PreventiveMaintenanceCompletionKPI[]> {
        const timeRange = dateRangeToTimeRange(params);
        if (customerNumber) {
            const query = queryString.stringify({ ...timeRange, splitBy: params.splitBy });

            const response = await queryCustomerAPI<PreventiveMaintenanceCompletionKPI[]>(
                `/customers/${customerNumber}/kpi/preventive-maintenance?${query}`,
            );
            if (response.status === 200) {
                return response.results;
            }
        }

        return [
            {
                all: {
                    completed: 0,
                    planned: 0,
                    percentage: 0,
                },
                byType: {},
                bySite: {},
                startTime: timeRange.startTime,
                endTime: timeRange.endTime,
            },
        ];
    },
    async loadResponseTimeKPI(params: KPIParams, customerNumber: string | null): Promise<ResponseTimeKPI[]> {
        const timeRange = dateRangeToTimeRange(params);
        if (customerNumber) {
            const query = queryString.stringify({ ...timeRange, splitBy: params.splitBy });
            const response = await queryCustomerAPI<ResponseTimeKPI[]>(
                `/customers/${customerNumber}/kpi/response-time?${query}`,
            );
            if (response.status === 200) {
                return response.results;
            }
        }

        return [
            {
                average_minutes: null,
                callouts: null,
                bySite: null,
                startTime: timeRange.startTime,
                endTime: timeRange.endTime,
            },
        ];
    },
    async loadSalesOrders(customerNumber: string | null): Promise<SalesOrder[]> {
        if (customerNumber) {
            const response = await queryCustomerAPI<SalesOrder[]>(`/customers/${customerNumber}/sales-orders`);
            if (response.status === 200) {
                return response.results;
            }
        }
        return [];
    },
    async loadComponents(customerNumber: string, serial_number: string): Promise<Components[]> {
        if (customerNumber) {
            const response = await queryCustomerAPI<Components[]>(
                `/customers/${customerNumber}/equipment/${serial_number}/components`,
            );
            if (response.status === 200) {
                return response.results;
            }
        }
        return [];
    },

    async loadMaintenancePlansForEquipment(customerNumber: string, equipmentId: string): Promise<MaintenancePlan[]> {
        const response = await queryCustomerAPI<MaintenancePlan[]>(
            `/customers/${customerNumber}/equipment/${equipmentId}/maintenance-plans`,
        );

        if (response.status === 200) {
            const nonDeclinedPlans = response.results.filter((plan) => plan.status !== MaintenanceStatus.DECLINED);
            return nonDeclinedPlans;
        }
        return [];
    },
    async getCustomer(customerNumber: string): Promise<Customer | null> {
        const response = await queryAdminAPI<Customer>(`/admin/customers/${customerNumber}`);
        if (response?.status === 200) {
            return response.results;
        }
        return null;
    },
    async loadInsightUsers(customerNumber: string): Promise<number | null> {
        const response = await queryAdminAPI<{ userCount: number }>(`/customers/${customerNumber}/insight-user-count`);
        if (response?.status === 200) {
            return response.results.userCount;
        }
        return null;
    },
    async getSessionUser(): Promise<CustomerUser | InternalUser | null> {
        const response = await queryAPI<ResultsResponse<CustomerUser | InternalUser>>(`/user`);
        if (response.status === 200) {
            return response.results;
        }
        return null;
    },
    async closeSession(): Promise<void> {
        await queryAPI<void>('/logout/close-session');
    },
    async updateUserFlags(flags: Partial<UserFlags>): Promise<UserFlags> {
        return postToAPI<Partial<UserFlags>, UserFlags>('/user/flags', flags);
    },
    subscribeToCaseUpdates(caseIds: string[], onPublish: (payload: CaseUpdatePayload) => void): void {
        if (!caseIds.length) return;

        const socket = io(`${mkEnv.apiBaseURL}/cases`, {
            rememberUpgrade: true,
            transports: ['websocket', 'polling'],
        });
        socket.on('connect', () => {
            socket.emit('subscribe', caseIds);
        });
        socket.on('publish', onPublish);
    },
};

const getLocalStorageLanguageKey = (username: string) => `language-${username}`;

function getLanguageImportFunc(lang: Language) {
    switch (lang) {
        case 'es':
            return () => import(`../translations/es`);
        case 'de':
            return () => import(`../translations/de`);
        case 'fr':
            return () => import(`../translations/fr`);
        case 'sv':
            return () => import(`../translations/sv`);
        case 'zh':
            return () => import(`../translations/zh`);
        default:
            return () => import(`../translations/en`);
    }
}

export const sideEffects = {
    async setLanguage(username: string, lang: Language): Promise<void> {
        try {
            const importFunc = getLanguageImportFunc(lang);

            const imported = await importFunc();

            const resource = imported.default as ResourceLanguage;
            Object.entries(resource).map(([namespace, translations]) => {
                i18n.addResourceBundle(lang, namespace, translations);
            });

            await i18n.changeLanguage(lang);

            if (localStorage) {
                localStorage.setItem(getLocalStorageLanguageKey(username), lang);
            }
        } catch (error) {
            logger.error(`Importing translation file for language "${lang}" failed`);
        }
    },
    getLanguage(username: string): Language | null {
        if (!localStorage) {
            return null;
        }
        return localStorage.getItem(getLocalStorageLanguageKey(username)) as Language | null;
    },
};
