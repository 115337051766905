import Modal from '@mui/material/Modal';
import { RouteComponentProps } from '@reach/router';
import React, { useLayoutEffect } from 'react';
import { useActions, useOvermindState } from '../../state';
import { cns } from '../../utils';
import { CustomerSelector } from '../CustomerSelector/CustomerSelector';
import { Footer } from '../Footer/Footer';
import { MasterHeader } from '../MasterHeader/MasterHeader';
import { SessionExpiredModal } from '../SessionExpiredModal/SessionExpiredModal';
import { SiteSelector } from '../SiteSelector/SiteSelector';
import { Toast } from '../Toast/Toast';
import css from './MainContainer.module.scss';

interface MainContainerProps extends RouteComponentProps {
    children: React.ReactNode;
}

export const MainContainer: React.FC<MainContainerProps> = ({ children }: MainContainerProps) => {
    // Scroll to top when location changes
    useLayoutEffect(() => window.scrollTo(0, 0), [location.pathname]);
    const { isCustomerSelectorModalOpen, isSessionExpired, selectedSite, sites, user } = useOvermindState();
    const { toggleCustomerSelectorModal, handleCustomerChange, handleSelectedSiteChange } = useActions();

    if (user === null) return null;

    return (
        <div className={css.mainContainer}>
            <MasterHeader />
            <Toast />
            <SiteSelector
                isCustomerSelectorVisible={user.isAdmin}
                openCustomerChangeModal={toggleCustomerSelectorModal}
                selectedSite={selectedSite}
                setSelectedSite={handleSelectedSiteChange}
                sites={sites}
            />

            {user.isAdmin && (
                <Modal open={isCustomerSelectorModalOpen} onClose={toggleCustomerSelectorModal}>
                    <CustomerSelector
                        handleCustomerChange={handleCustomerChange}
                        onClose={toggleCustomerSelectorModal}
                    />
                </Modal>
            )}
            <Modal open={isSessionExpired}>
                <SessionExpiredModal />
            </Modal>
            <section className={cns(css.content, css.centeredContainer)}>{children}</section>
            <Footer />
        </div>
    );
};
