export default {
    App: {
        'Contact Kalmar': 'Contacter Kalmar',
        'Create new': 'Créer nouveau',
        'Download failed': 'Échec du téléchargement',
        'Failed to get service reports': 'Échec de l\'obtention des comptes-rendus d’entretien',
        'Loading MyKalmar': 'Chargement de MyKalmar',
        'Login again': 'Se reconnecter',
        'Maintenance reporting': 'Rapport d\'entretien',
        'Maintenance schedule': 'Maintenancier',
        'My Cases': 'Mes cas',
        'MyKalmar homepage': 'Page d’accueil de MyKalmar',
        'MyParts': 'MyParts',
        'Navigating to MyParts failed': 'Échec de l’accès à MyParts',
        'No service reports found': 'Aucun compte-rendu d’entretien trouvé',
        'None': 'Aucun',
        'Open Kalmar Insight': 'Ouverture Kalmar Insight',
        'Open MyKalmar STORE': 'Ouvrir MyKalmar STORE',
        'Parts order history': 'Historique des commandes',
        'Show all ({{ equipmentLength }}) equipment': 'Afficher tous les équipements ({{ equipmentLength }})',
        'Show all Kalmar contacts': 'Afficher tous les contacts Kalmar',
        'Show cases': 'Afficher les cas',
        'Show on calendar': 'Afficher sur le calendrier',
        'Try again': 'Réessayer',
        'You are now logged out from MyKalmar': 'Vous êtes maintenant déconnecté de MyKalmar',
    },
    Common: {
        'Availability': 'Disponibilité',
        'Call the number': 'Appeler le numéro',
        'called': 'Appelé',
        'completed': 'Terminé',
        'Contracts': 'Contrats',
        'declined': 'Refusé',
        'Maintenance': 'Maintenance',
        'Monthly': 'Tous les mois',
        'Monthly report': 'Rapport mensuel',
        'My Equipment': 'Mon équipement',
        'N/A': 'N/A',
        'Name': 'Nom',
        'No data': 'Pas de données',
        'No data for this time period': 'Pas de données pour cette période',
        'OK': 'Ok',
        'ordered': 'Commandé',
        'Other': 'Autre',
        'Response Time': 'Temps de réponse',
        'scheduled': 'Planifié',
        'See details': 'Voir les détails',
        'Serial number': 'Numéro de série',
        'Site': 'Site',
        'Something went wrong': 'Un problème est survenu',
        'Status': 'État',
        'today': 'aujourd\'hui',
        'Type': 'Type',
        'Unknown': 'Inconnu',
        'Yearly': 'Tous les ans',
        'Yearly trend': 'Tendance annuelle',
    },
    ContactWidget: {
        'Contact us!':'Contactez-nous!',
        'Email': 'E-mail',
        'Need help?  Or need to know more about our services?': 'Besoin d\'aide? Ou besoin d\'en savoir plus sur notre service ? ',
        'Phone': 'Téléphone',
        'Send email': 'Envoyer un email',
    },
    CookieConsentBanner: {
        'Learn more': 'Apprendre encore plus',
        'This website makes use of cookies to enhance browsing experience and provide additional functionality.': 'Cette page Web utilise des cookies pour mettre en valeur l\'expérience et fournir des fonctions additionnelles',
    },
    CustomerSelector: {
        'No results found with': 'Aucun résultat trouvé avec',
        'Search with name, city, street address or sold-to number': 'Search with name, city, street address or sold-to number',
        'Select customer': 'Sélectionner un client',
    },
    EquipmentAvailability: {
        'Equipment Type': 'Type d\'équipem.',
        'Month': 'Mois',
        'Quarter': 'Trimestre',
        'Year': 'Année',
    },
    EquipmentAvailabilityByType: {
        'Time spent': 'Temps écoulé',
    },
    Footer: {
        'General conditions': 'Conditions générales',
        'Kalmar in Social Media': 'Kalmar sur les réseaux sociaux',
        'Kalmar Digital Service Terms & Conditions': 'Conditions générales du service numérique Kalmar',
        'Privacy policy': 'Politique de confidentialité',
    },
    KalmarInsightWidget: {
        'equipment': 'Équipement',
        'Expired license': 'Licence expirée',
        'License expiring soon': 'La licence va bientôt expirer',
        'Manage users': 'Gestion utilisateurs',
        'Number of Kalmar Insight users': 'Nombre d\'utilisateurs Insight',
        'Valid license': 'Licence valide',
    },
    LanguageSelector: {
        'Cancel': 'Annuler',
        'Select language': 'Choisir la langue',
    },
    LoadingFailure: {
        'Loading cases failed': 'Échec du chargement des cas',
        'Loading equipment availability failed': 'Échec du chargement de la disponibilité de l\'équipement',
        'Loading equipment failed': 'Échec du chargement de l’équipement',
        'Loading failed': 'Échec du chargement',
        'Loading maintenance schedule failed': 'Échec du chargement de la périodicité d’entretien',
        'Loading preventive maintenance data failed': 'Échec du chargement des données d’entretien préventif',
        'Loading response time failed': 'Échec du chargement du temps de réponse',
        'Loading sales orders failed': 'Échec du chargement des bons de commande',
        'Retry': 'Réessayer',
    },
    MaintenanceCalendar: {
        'Equipment name': 'Nom de l\'équipement',
        'Maintenance description': 'Description de l’entretien',
        'No maintenance scheduled for the selected day.': 'Aucun entretien planifié pour le jour sélectionné.',
    },
    MaintenanceReporting: {
        'Equipment Availability': 'Disponibilité de l’équipement',
        'Preventive Maintenance': 'Entretien préventif',
    },
    MaintenanceReportingWidget: {
        'Average duration from breakdown to the first service operation start': 'Average duration from breakdown to the first service operation start',
        'Average percentage the equipment was available': 'Pourcentage moyen de disponibilité de l’équipement',
        'Planned maintenance operations that were completed on time': 'Planned maintenance operations that were completed on time',
    },
    MaintenanceScheduleWidget: {
        'No upcoming maintenances': 'Aucun entretien à venir',
        'Upcoming maintenances': 'Entretiens à venir',
    },
    MasterHeader: {
        'Go to MyKalmar STORE or Kalmar Insight': 'Aller dans MyKalmar STORE or Kalmar Insight',
        'Language': 'Langue',
        'Log out': 'Déconnexion',
        'MyKalmar profile': 'MyKalmar Profil',
        'You can go to MyKalmar STORE and Kalmar Insight by clicking here': 'Vous pouvez aller dans MyKalmar STORE et Kalmar Insight en cliquant ici',
    },
    MyCasesWidget: {
        'An unknown value': 'Une valeur inconnue',
        'caseUpdateFieldChange': '{{fieldName}} changé de <2>{{oldValue}}</2> en <4>{{newValue}}</4>',
        'Comment': 'Commentaire',
        'n/a': 'n/a',
        'New comment': 'Nouveau commentaire',
        'No recent updates to cases': 'Aucune mise à jour récente des cas',
        'Priority': 'Priorité',
        'Recent updates': 'Mises à jour récentes',
        'Resolution_Date__c': 'Resolution date',
    },
    MyEquipment: {
        'Contracts': 'Contrats',
        'Description': 'Description',
        'Digital subscriptions': 'Abonnements numériques',
        'Equipment name': 'Nom de l’équipement',
        'Insight': 'Aperçu',
        'Leasing': 'Location',
        'Model': 'Modèle',
        'Search equipment with name, site, serial number or type': 'Rechercher un équipement par nom, site, numéro de série ou type',
        'Search with name, serial number or type': 'Rechercher par nom, numéro de série ou type',
        'Serial number': 'Numéro de série',
        'Short-term rental': 'Location courte durée',
    },
    MyEquipmentItem: {
        'Contract ends': 'Fin du contrat',
        'Contract started': 'Début du contrat',
        'Description': 'Description',
        'Equipment details': 'Détails de l’équipement',
        'insight': 'Aperçu',
        'leasing': 'Location',
        'maintenance': 'Maintenance',
        'Maintenance schedule': 'Périodicité d’entretien',
        'No equipment found': 'Aucun équipement trouvé',
        'Order parts': 'Commander des pièces',
        'Owner': 'Propriétaire',
        'rental-short-term': 'Location courte durée',
        'See Insights': 'Voir les aperçus',
        'Serial number': 'Numéro de série',
        'Today': 'Aujourd\'hui',
    },
    MyEquipmentWidget: {},
    MyPartsWidget: {
        'At warehouse': 'En entrepôt',
        'Customer PO': 'PO client',
        'Delivered': 'Livré',
        'My last parts orders': 'Mes dernières commandes de pièces',
        'No orders': 'Aucune commande',
        'Note: this order will be delivered in multiple shipments.': 'Remarque : Cette commande sera livrée en plusieurs envois.',
        'Order number': 'Numéro de commande',
        'Order received': 'Commande reçue',
        'Processing': 'En traitement',
        'Shipment': 'Envoi',
        'Shipped': 'Expédiée',
        'Tracking': 'Suivi',
    },
    NotFound: {
        'Go to home page': 'Aller à la page d’accueil',
        'Page not found': 'Page introuvable',
        'The page may have been moved or you may not have the necessary rights to access this page': 'La page a peut-être été déplacée ou vous ne disposez peut-être pas des droits nécessaires pour accéder à cette page',
    },
    PreventiveMaintenanceCompletion: {
        'Completed': 'Terminé',
        'completed in': 'terminé dans',
        'Count': 'Nombre',
        'No data for': 'Pas de données pour',
        'Not completed': 'Non terminé',
        'Percentage': 'Pourcentage',
        'Planned': 'Planifié',
        'tasks': 'tâches',
    },
    ResponseTime: {
        'Action started': 'Action démarrée',
        'avg. response time': 'temps de réponse moy.',
        'Call started': 'Appel démarré',
        'in': 'in',
        'Operation': 'Utilisation',
        'Response time': 'Temps de réponse',
    },
    SessionExpirationModal: {
        'Log in again': 'Reconnectez-vous',
        'You have been logged out due to inactivity': 'Vous avez été déconnecté pour cause d\'inactivité',
        'Your session has expired': 'Votre session a expiré',
    },
    SiteSelector: {
        'Customer': 'Client',
        'No sites with equipment': 'Aucun site avec équipement',
        'Select a customer': 'Sélectionner un client',
        'Selected': 'Sélectionné',
        'Show all sites': 'Montrer tous les sites',
        'Showing all sites': 'Affichage de tous les sites',
    },
    TimeUnitLinks: {
        'Show': 'Afficher',
    },
};
